import axios from "axios";
import { useEffect, useState } from "react";

export const useCountryIP = () => {
	const [countryName, setCountryName] = useState(null);

	useEffect(() => {
		axios
			.get("https://ipapi.co/json/")
			.then((response) => {
				setCountryName(response?.data?.country_name);
			})
			.catch((error) => {
				// eslint-disable-next-line no-console
				console.log("ip api error:-->", error);
			});
	}, []);

	return countryName;
};
