import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import createThemeStyles from "@schemas/commons/getStyles";
import { createSchema } from "@utils/index";

import { instance } from "../../../../instances/configImport";

const isIADEES = instance.includes("iadeES");
const isIADE = instance.includes("IADE");
const isUDDI = instance.includes("UDDI");

const currentTheme =
	isIADEES || isUDDI ? "defaultAlt" : isIADE ? "default" : "accent";

export default createSchema({
	schemaType: "module",
	displayName: "Header One Line",
	component: "HeaderOneLine",
	type: "header",
	category: "header",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Name",
					key: "title",
					type: "TextField",
					mandatory: true,
					humanReadable: true,
				},
				{
					title: "Button",
					type: "ComponentContainer",
					whiteList: ["Button"],
					key: "button",
					mandatory: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					type: "UniqueCheck",
					key: "setAsDefault",
					options: [{ title: "Set as Header default" }],
				},
				{
					title: "Main menu",
					type: "AsyncSelect",
					entity: "menu_containers",
					key: "mainMenu",
					mandatory: true,
				},
				{
					title: "Style",
					key: "theme",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: [
						{
							...createThemeStyles("garrigues-theme", ["accent"]),
						},
						{
							...createThemeStyles("eeg-theme", ["accent"]),
						},
						{
							...createThemeStyles("ipam-theme", ["accent"]),
						},
						{
							...createThemeStyles("iade-theme", ["default"]),
						},
						{
							...createThemeStyles("iade-es-theme", ["defaultAlt"]),
						},
						{
							...createThemeStyles("uddi-theme", ["defaultAlt"]),
						},
					],
					columns: 8,
				},
			],
		},
	],

	default: {
		component: "HeaderOneLine",
		type: "header",
		title: "Header One Line",
		note01: {
			title: "",
			text: "To configure social links go to settings/general/social. To activate search feature go to settings/actionables",
		},
		button: {
			component: "Button",
			buttonText: "Infórmate",
			appearance: "primary",
			size: "medium",
			url: {
				href: "comunicacion@europaeducationgroup.com",
				linkTo: null,
				newTab: false,
				noFollow: false,
			},
		},
		setAsDefault: true,
		mainMenu: null,
		theme: currentTheme,
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/HeaderOneLine.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/HeaderOneLine.png`,
	},
});
