export default {
	ARTICLES_EVENTS: { title: "Articles & Events" },
	AWARDS: { title: "Awards" },
	COMPANIES: { title: "Companies" },
	LANDINGS: { title: "Landings" },
	NEWS: { title: "News" },
	PEOPLE: { title: "People" },
	STUDY_LANDING_TYPE: { title: "Study Type Landing" },
	STUDY_LANDING: { title: "Study Landing" },
	STUDY: { title: "Study" },
	BLOG: { title: "Blog" },
	JOB_OFFERS: { title: "Job Offers" },
	MODALS: { title: "Modals" },
	QUIZZ: { title: "Quizz" },
	DISCOUNTS: { title: "Discounts" },
	ALUMNI: { title: "Alumni" },
	WORD: { title: "Word" },
};
