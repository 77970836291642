import TitleAndSubtitle from "@schemas/commons/TitleAndSubtitle";

export default {
	schemaType: "template",
	displayName: "Alumni List",
	component: "AlumniList",
	dataPacks: ["ALUMNI"],
	type: { label: "Alumni", value: "ALUMNI" },
	content: [
		{ ...TitleAndSubtitle.content[0], key: "title" },
		{ ...TitleAndSubtitle.content[1], key: "subtitle" },
		{
			title: "Items per page",
			type: "NumberField",
			key: "itemsPerPage",
			mandatory: true,
		},
		{
			title: "Filters Data",
			type: "ReferenceField",
			sources: [
				{ structuredData: "ALUMNI_PROGRAM_TYPE" },
				{ structuredData: "ALUMNI_PROGRAM" },
				{ structuredData: "ALUMNI_PROMOTION_YEAR" },
			],
			key: "data",
		},
	],

	default: {
		type: "template",
		templateType: "AlumniList",
		title: { content: "Title", tag: "h2" },
		subtitle: "Lorem Ipsum",
		itemsPerPage: 12,
		hasDistributorData: true,
		data: {
			mode: "auto",
			sources: [
				{ structuredData: "ALUMNI_PROGRAM_TYPE" },
				{ structuredData: "ALUMNI_PROGRAM" },
				{ structuredData: "ALUMNI_PROMOTION_YEAR" },
			],
			order: "alpha",
			quantity: 0,
		},
	},

	thumbnails: {},
};
