const { instance } = require("../../../instances/current.json");

const pt_PT = {
	name: "Portuguese",
	label: "PT",
};
const es = {
	name: "Spanish",
	label: "ES",
	alias: ["es_ES"],
};
const en = {
	name: "English",
	label: "EN",
	alias: ["en_GB"],
};

const ca = {
	name: "Catalan",
	label: "CA",
	alias: ["ca_ES"],
};

let languages;

if (instance.includes("IPAM") || instance.includes("IADE")) {
	languages = { pt_PT, es, en };
} else if (instance.includes("iadeES")) {
	languages = { es, en, pt_PT };
} else if (instance.includes("UDDI")) {
	languages = { es, en, pt_PT, ca };
} else {
	languages = { es, en, pt_PT };
}

export default languages;
