import { additionalModuleList, basicModuleList } from "@schemas/modules";
import { createSchema } from "@utils/index";

export default createSchema({
	schemaType: "template",
	displayName: "Word",
	component: "Word",
	dataPacks: ["WORD"],
	type: { label: "Word", value: "word", mode: "detail" },
	content: [
		{
			title: "Image",
			type: "ImageField",
			key: "image",
		},
		{
			title: "Main Title",
			type: "HeadingField",
			humanReadable: true,
			key: "mainTitle",
		},
		{
			title: "Content",
			type: "Wysiwyg",
			humanReadable: true,
			key: "content",
		},
		{
			title: "Main Section",
			type: "ComponentArray",
			maxItems: null,
			whiteList: [...basicModuleList, ...additionalModuleList],
			key: "mainSection",
		},
	],
	default: {
		type: "template",
		templateType: "Word",
		mainTitle: { content: "Title", tag: "h1" },
		image: "",
		content: "",
		mainSection: {
			component: "Section",
			name: "Main Section",
			modules: [],
		},
	},
});
