const { instance } = require("../../../instances/current.json");

export default {
	NEWS_AREAS: {
		title: "News Areas",
		dataPacks: instance.includes("CEG") ? ["NEWS"] : ["NEWS", "BLOG"],
		local: false,
		taxonomy: true,
		fromPage: false,
		translate: true,
		schema: null,
		clone: null,
		defaults: null,
	},
};
