import { PageContext } from "@contexts";
import { useContext } from "react";

export const useI18nArray = () => {
	const { languageId, pageLanguages } = useContext(PageContext);

	const currentLocale =
		pageLanguages?.filter((locale) => locale.languageId === languageId)[0]
			?.locale || "es"; // TODO NO ESTA PILLANDO EL LOCALE

	// eslint-disable-next-line no-unused-vars
	const getTranslatedArray = ({ arrayData = [], options = {} }) => {
		return arrayData.map((i) => ({
			...i,
			translatedLabel: i[`label_${currentLocale}`] || i.label,
		}));
	};

	return {
		getTranslatedArray,
	};
};
