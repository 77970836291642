import CLOUDINARY_BASE_UPLOAD_URL from "@constants/cloudinary/base-upload-url";
import { instance } from "@instance-config";
import createThemeStyles from "@schemas/commons/getStyles";
import { createSchema } from "@utils/index";

// eslint-disable-next-line no-unused-vars
const isUDDI = instance.includes("UDDI");

export default createSchema({
	schemaType: "module",
	displayName: "Hero Home",
	component: "HeroHome",
	category: "featured",
	dataPacks: null,
	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Slide elements",
					type: "ComponentArray",
					maxItems: null,
					whiteList: ["HeroCard"],
					key: "elements",
					mandatory: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					title: "Style",
					key: "theme",
					type: "VisualUniqueSelection",
					mandatory: true,
					options: [
						{
							...createThemeStyles("garrigues-theme", [
								"default",
								"defaultAlt",
							]),
						},
						{
							...createThemeStyles("eeg-theme", [
								"default",
								"defaultAlt",
								"accent",
							]),
						},
						{
							...createThemeStyles("ipam-theme", ["default", "accent"]),
						},
						{
							...createThemeStyles("iade-theme", ["default", "accent"]),
						},
						{
							...createThemeStyles("iade-es-theme", ["default", "defaultAlt"]),
						},
						{
							...createThemeStyles("uddi-theme", ["default"]),
						},
					],
					columns: 8,
				},
			],
		},
	],

	default: {
		component: "HeroHome",
		elements: [
			{
				component: "HeroCard",
				backgroundImage: {
					component: "Image",
					alt: "Alt",
					file: {
						publicId:
							"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
					},
					veil: 0,
				},
				title: {
					content: "Title",
					tag: "h4",
				},
				subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				button: {
					component: "Button",
					buttonText: "Lorem Ipsum",
					appearance: "secondary",
					size: "big",
					url: {
						href: "",
						linkTo: null,
						newTab: false,
						noFollow: false,
					},
				},
			},
			{
				component: "HeroCard",
				backgroundImage: {
					component: "Image",
					alt: "Alt",
					file: {
						publicId:
							"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
					},
					veil: 0,
				},
				title: {
					content: "Title",
					tag: "h4",
				},
				subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				button: {
					component: "Button",
					buttonText: "Lorem Ipsum",
					appearance: "secondary",
					size: "big",
					url: {
						href: "",
						linkTo: null,
						newTab: false,
						noFollow: false,
					},
				},
			},
			{
				component: "HeroCard",
				backgroundImage: {
					component: "Image",
					alt: "Alt",
					file: {
						publicId:
							"CegDEV/CEG-HeroPhoto_93f16f65-d9b4-446b-b59f-11a8d320907e",
					},
					veil: 0,
				},
				title: {
					content: "Title",
					tag: "h4",
				},
				subtitle:
					"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
				button: {
					component: "Button",
					buttonText: "Lorem Ipsum",
					appearance: "secondary",
					size: "big",
					url: {
						href: "",
						linkTo: null,
						newTab: false,
						noFollow: false,
					},
				},
			},
		],
		theme: "default",
	},
	thumbnails: {
		"1x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/HeroHome_x2.png`,
		"2x": `${CLOUDINARY_BASE_UPLOAD_URL}/thumbnails/HeroHome_x2.png`,
	},
});
