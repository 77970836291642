import createThemeStyles from "@schemas/commons/getStyles";

export default {
	schemaType: "template",
	displayName: "Quizz",
	component: "Quizz",
	dataPacks: ["QUIZZ"],
	type: { label: "Quizz", value: "quizz", mode: "detail" },
	content: [
		// QUESTIONS
		{
			title: "Intro",
			type: "RichText",
			key: "intro",
			mandatory: true,
		},
		{
			title: "Start Button Text",
			type: "TextField",
			key: "buttonText",
			mandatory: true,
		},
		{
			title: "Background",
			type: "ImageField",
			key: "background",
			hideable: true,
		},
		{
			title: "Mobile Background",
			type: "ImageField",
			key: "mobileBackground",
			hideable: true,
		},
		{
			title: "Mobile Questions Image",
			type: "ImageField",
			key: "mobileImage",
			hideable: true,
		},
		{
			type: "ArrayFieldGroup",
			title: "Quizz Questions",
			key: "questions",
			name: "Question",
			fields: [
				{
					title: "Text",
					type: "TextField",
					key: "text",
				},
				{
					title: "Image",
					type: "ImageField",
					key: "image",
				},
				{
					type: "ArrayFieldGroup",
					title: "Question Answers",
					key: "answers",
					name: "Answer",
					fields: [
						{
							title: "Text",
							type: "TextField",
							key: "text",
						},
						{
							title: "Studies linked to this answer",
							type: "AsyncCheckGroup",
							source: "STUDY",
							key: "studies",
						},
					],
				},
			],
		},
		{
			title: "Salesforce ID",
			type: "TextField",
			key: "salesforceId",
		},
		{
			title: "Campaign ID",
			type: "TextField",
			key: "campaignId",
		},

		// FORM
		{
			title: "Form Title",
			type: "TextField",
			key: "formTitle",
		},
		{
			title: "Form Intro",
			type: "RichText",
			key: "formIntro",
		},

		// RESULTS
		{
			title: "Result Title",
			type: "TextField",
			key: "resultTitle",
		},
		{
			title: "Result Intro",
			type: "RichText",
			key: "resultIntro",
		},
		{
			title: "Disable results list",
			type: "RadioGroup",
			key: "disableResults",
			options: [
				{
					title: "No",
					name: "no",
					value: "no",
				},
				{
					title: "Yes",
					name: "yes",
					value: "yes",
				},
			],
		},

		{
			title: "Style",
			key: "theme",
			type: "VisualUniqueSelection",
			mandatory: true,
			options: [
				{
					...createThemeStyles("ipam-theme", [
						"defaultAlt",
						"accentAlt",
						"accentLight",
					]),
				},
				{
					...createThemeStyles("iade-theme", [
						"defaultAlt",
						"accentAlt",
						"accentLight",
					]),
				},
				{ ...createThemeStyles("iade-es-theme", ["defaultAlt"]) },
				{ ...createThemeStyles("uddi-theme", ["defaultAlt"]) },
			],
			columns: 8,
		},
	],

	default: {
		type: "template",
		templateType: "Quizz",
		intro: "Lorem ipsum",
		buttonText: "Let's get started",
		disableResults: "no",
		theme: "defaultAlt",
	},

	thumbnails: {
		"1x": "/thumbnails/templates/Quizz/thumbnail@1x.png",
		"2x": "/thumbnails/templates/Quizz/thumbnail@2x.png",
	},
};
